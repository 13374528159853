<template>
  <div class="resource">
    <div class="classify">
      <div v-for="(item, index) in types" :key="index" class="wrap flex jc-between ai-center">
        <div class="label">{{ item.name }}</div>
        <div class="list flex-1 flex ai-center">
          <div v-for="(child, idx) in item.list" :key="idx"
            :class="['item cursor-pointer', { 'actived': item.actived === child.id }]"
            @click="handleTypeClick(item, child)">{{
              child.name }}</div>
        </div>
      </div>
    </div>
    <div class="table flex flex-wrap">
      <div v-for="(item, index) in list" :key="index" class="item flex flex-column relative cursor-pointer">
        <!-- <img :src="item.fileUrl" :alt="item.fileName" class="img absolute"> -->
        <!-- fileType	string 0文本文档 1视频资料 2全图资料 3模型资料 -->
        <video
          v-if="item.fileType == '1'"
          class="img absolute"
          disablePictureInPicture
          controlslist="nodownload nofullscreen noremoteplayback noplaybackrate"
          controls
          :src="item.fileUrl"
        />
        <el-image v-else-if="item.fileType == '2'" class="img absolute" :src="item.fileUrl" fit="contain" />
        <div v-else-if="item.fileType == '0'" class="img absolute">
          <iframe :src="item.videoSrc" frameborder="0" class="iframe"></iframe>
        </div>
        <div v-else-if="item.fileType == '3'" class="img absolute">
          <iframe :src="'https://view.officeapps.live.com/op/view.aspx?src=' + item.fileUrl" frameborder="0" class="iframe"></iframe>
        </div>
        <!-- <div v-else-if="item.fileType == 'pdf'" class="img absolute">
          <iframe :src="item.videoSrc" frameborder="0" class="iframe"></iframe>
        </div> -->
        <!-- <div v-else-if="item.fileType == 'doc'" class="img absolute">
          <iframe :src="'https://view.officeapps.live.com/op/view.aspx?src=' + item.fileUrl" frameborder="0" class="iframe"></iframe>
        </div> -->
        <!-- <div v-else-if="item.fileType == 'txt'" class="img absolute">
          <iframe :src="item.videoSrc" frameborder="0" class="iframe"></iframe>
        </div> -->
        <div v-else class="img absolute">
          <iframe :src="'https://view.officeapps.live.com/op/view.aspx?src=' + item.fileUrl" frameborder="0" class="iframe"></iframe>
        </div>
        <div class="txt absolute">{{ item.fileName }}</div>
      </div>
    </div>
    <div class="pagination">
      <el-pagination align="center" @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="page" :page-sizes="[10, 20, 40, 50, 100]" :page-size="size"
        layout="total, sizes, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </div>
    <!-- <img src="../../assets/pcResource.png" alt=""> -->
  </div>
</template>
<script>
import { getResource } from '@/api/ApiConfig'
export default {
  components: {
  },
  data() {
    return {
      types: [
        {
          id: 0,
          name: '专业大类：',
          actived: '',
          list: [
            {
              id: '',
              name: '全部'
            },
            {
              id: '0',
              name: '计算机'
            },
            {
              id: '1',
              name: '医学'
            },
            {
              id: '2',
              name: '音乐'
            },
            {
              id: '3',
              name: '建筑'
            }
          ]
        },
        {
          id: 2,
          name: '课程类型：',
          actived: '',
          list: [
            {
              id: '',
              name: '全部'
            },
            {
              id: '0',
              name: '编程'
            },
            {
              id: '1',
              name: '3D建模'
            },
            {
              id: '2',
              name: '设计'
            },
            {
              id: '3',
              name: '网络'
            }
          ]
        },
        {
          id: 3,
          name: '课程系列：',
          actived: '',
          list: [
            {
              id: '',
              name: '全部'
            },
            {
              id: '0',
              name: '建筑领域'
            },
            {
              id: '1',
              name: '服务领域'
            },
            {
              id: '2',
              name: '计算机领域'
            },
            {
              id: '3',
              name: '金融领域'
            }
          ]
        },
        {
          id: 4,
          name: '文件类型：',
          actived: '',
          list: [
            {
              id: '',
              name: '全部'
            },
            {
              id: '0',
              name: '文本文档'
            },
            {
              id: '1',
              name: '视频资料'
            },
            {
              id: '2',
              name: '全景图资料'
            },
            {
              id: '3',
              name: '模型资料'
            }
          ]
        }
      ],
      page: 1,
      size: 10,
      total: 0,
      list: []
    };
  },
  created() {
    this.getData()
  },
  activated() {
  },
  methods: {
    handleTypeClick(item, child) {
      item.actived = child.id
      this.page = 1
      this.getData()
    },
    getData() {
      getResource({
        pageIndex: this.page,
        pageSize: this.size,
        courseSeries: this.types[2].actived,
        courseType: this.types[1].actived,
        fileType: this.types[3].actived,
        speciality: this.types[0].actived
      }).then(res => {
        if (res.code === 200) {
          console.log(res.data)
          this.list = res.data.records || [];
          this.total = res.data.total
        }
      });
    },
    handleSizeChange(val) {
      this.page = 1;
      this.size = val;
      this.getData();
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getData();
    },
  }
};
</script>
<style lang="scss">
.resource {
  padding-top: 109px;

  .classify {
    width: 1400px;
    background: #FFFFFF;
    box-shadow: 0px 2px 14px 0px rgba(45, 77, 130, 0.11);
    border-radius: 12px;
    padding: 20px 30px;
    margin: 0 auto;

    .wrap {
      padding: 16px 0;

      .label {
        width: 90px;
        height: 25px;
        font-weight: bold;
        font-size: 18px;
        color: #327CED;
        line-height: 25px;
        text-align: left;
        font-style: normal;
      }

      .item {
        font-size: 16px;
        color: #697B95;
        line-height: 38px;
        padding: 0 16px;

        &.actived {
          background: #327CED;
          border-radius: 6px;
          color: #FFFFFF;
        }
      }
    }
  }

  .table {
    width: 1400px;
    margin: 20px auto;

    .item {
      width: 280px;
      height: 264px;

      .img {
        left: 0;
        top: 0;
        width: 280px;
        height: 264px;
        .iframe {
          width: 100%;
          height: 100%;
        }
      }

      .txt {
        left: 0;
        bottom: 20px;
        z-index: 2;
        width: 280px;
        font-weight: bold;
        font-size: 18px;
        color: #234984;
        line-height: 25px;
        text-align: center;
      }
    }
  }

  .pagination {
    margin-top: 20px;
  }
}
</style>
