export const BASEURL = "https://ai.cyzntech.com";
import { loginreq, req } from "./request";

// 图片查看
// export const UploadView = (params) => { return req("get", "/studyPc/upload/view", params) };
// 发送验证码
export const sendEmailCode = params => {
  return req("post", "/studyPc/login/avoid/sendEmailTo", params);
};
// 忘记密码
export const forgetUpPwd = params => {
  return req("post", "/studyPc/login/avoid/forgetUpPwd", params);
};
// 登录
export const loginPc = params => {
  return req("post", "/studyPc/login/avoid/loginPc", params);
};

// 首页-轮播图
export const carouselList = params => {
  return req("get", "/studyPc/homepage/avoid/carouselList", params, "query");
};

// 首页-排行榜量
export const channelChartNum = params => {
  return req("get", "/studyPc/homepage/avoid/channelChartNum", params, "query");
};

// 首页-新闻或通知列表
export const noticeList = params => {
  return req("post", "/studyPc/homepage/avoid/noticeList", params);
};

// 首页-实验或课程列表
export const studyList = params => {
  return req("post", "/studyPc/homepage/avoid/studyList", params);
};

// 首页-收藏
export const collectItem = params => {
  return req("post", "/studyPc/homepage/collect", params);
};

// 首页-点赞
export const studyLike = params => {
  return req("post", "/studyPc/homepage/studyLike", params);
};

// 首页-实验项目情况
export const studyJoinNum = params => {
  return req("post", "/studyPc/study/avoid/studyJoinNum", params);
};

// 首页-查看记录
export const showRecord = params => {
  return req("post", "/studyPc/homepage/showRecord", params);
};

// 新闻通知列表
export const NoticeModuleList = params => {
  return req("post", "/studyPc/notice/noticeModuleList", params);
};

// 实验或课程列表
export const studyModuleList = params => {
  return req("post", "/studyPc/study/avoid/studyModuleList", params);
};

// 实验或课程详情
export const queryStudyDetail = params => {
  return req("post", "/studyPc/study/avoid/queryStudyDetail", params);
};

// 实验或课程侧边-分类列表
export const studyType = params => {
  return req("get", "/studyPc/study/avoid/studyType", params, "query");
};

// 实验或课程侧边-二级分类列表
export const studyType2 = params => {
  return req("get", "/studyPc/study/avoid/studyType2", params, "query");
};

// 课程-评价
export const inStudyEvaluate = params => {
  return req("post", "/studyPc/study/inStudyEvaluate", params);
};

// 课程-评分
export const inStudyScore = params => {
  return req("post", "/studyPc/study/inStudyScore", params);
};

// 课程-我的评分
export const myStudyScore = params => {
  return req("post", "/studyPc/study/myStudyScore", params);
};
// 课程-我的评价
export const myStudyEvaluate = params => {
  return req("post", "/studyPc/study/myStudyEvaluate", params);
};

// 课程评价列表
export const courseReply = params => {
  return req("post", "/studyPc/study/avoid/courseReply", params);
};
// 用户评价列表
export const userEvaluateList = params => {
  return req("post", "/studyPc/study/avoid/userEvaluateList", params);
};
// 课程-综合评分
export const comprehensiveDivide = params => {
  return req("post", "/studyPc/study/avoid/comprehensiveDivide", params);
};
// 课程-推荐列表
export const classRecommend = params => {
  return req("get", "/studyPc/study/avoid/classRecommend", params, "query");
};
// 我要上课
export const gotoClass = params => {
  return req("post", "/studyPc/study/gotoClass", params);
};
// 视频观看日志接口
export const inStudyJointLog = params => {
  return req("post", "/studyPc/study/inStudyJointLog", params);
};
// 我的课程观看视频记录
export const myStudyViewsJointLog = params => {
  return req("post", "/studyPc/study/myStudyViewsJointLog", params);
};
// 课程和实验评论
export const inStudyReply = params => {
  return req("post", "/studyPc/study/inStudyReply", params);
};

// 个人中心 我的信息
export const MyInfo = params => {
  return req("post", "/studyPc/myInfo/myInfo", params);
};
// 个人中心 收藏量
export const MyCollect = params => {
  return req("post", "/studyPc/myInfo/myCollect", params);
};
// 个人中心 修改我的信息
export const UpMyInfo = params => {
  return req("post", "/studyPc/myInfo/upMyInfo", params);
};

// 个人中心 绑定邮箱
export const BindEmail = params => {
  return req("post", "/studyPc/myInfo/bindEmail", params);
};
// 个人中心 修改密码
export const UpPwd = params => {
  return req("post", "/studyPc/myInfo/upPwd", params);
};
// 个人中心 浏览记录
export const MyView = params => {
  return req("post", "/studyPc/myInfo/myView", params);
};

// 资料文件 
export const getResource = params => {
  return req("post", "/studyPc/infoFile/avoid/infoFileList", params);
};

// 课题模块下拉列表 
export const topicModuleList = params => {
  return req("get", "/studyPc/topic/topicModuleList", params);
};

// 课题模块下拉列表 
export const topicList = params => {
  return req("post", "/studyPc/topic/topicList", params);
};

// 获取系统设置
export const getSysInfo = params => {
  return req("get", "/studyPc/sysInfo/avoid/sysInfo", params);
};

// 系统设置
export const setSysInfo = params => {
  return req("post", "/studyPc/study/upStudySysInfo", params);
};