<template>
  <div class="questions-pager">
    <div class="container flex jc-between ai-normal">
      <div class="container-left">
        <div class="menu">
          <div v-for="(item, index) in menu" :key="index" :class="['menu-item cursor-pointer', { actived : actived == index }]" @click="handleTab(index)">{{ item.name }}</div>
        </div>
      </div>
      <div v-if="actived === 0 || actived === 1" class="container-right flex-1">
        <div class="container-top flex jc-between ai-center">
          <el-select class="model" v-model="moduleId" placeholder="请选择模块" @change="handleChangeModule()">
            <el-option
              v-for="item in modules"
              :key="item.id"
              :label="item.name"
              :value="item.id"
              class="custom-option"
              style="">
            </el-option>
          </el-select>
          <div class="flex ai-center">
            <el-button :type="type === 1 ? 'primary' : 'default'" :class="['type-btn', { actived: type === 1 }]" @click="handleTypeClick(1)">正确题</el-button>
            <el-button :type="type === 0 ? 'primary' : 'default'" :class="['type-btn', { actived: type === 0 }]" @click="handleTypeClick(0)">错误题</el-button>
            <el-date-picker v-model="date" type="date" placeholder="请选择参与日期" />
          </div>
        </div>
        <div class="container-content">
          <div v-if="list.length > 0" class="question-content">
            <div v-for="(item, index) in list" :key="index" class="wrap">
              <div v-if="item.topic" class="title">{{ index + 1 }}、 {{ item.topic }}</div>
              <div v-if="item.topicImage" class="title"><img :src="item.topicImage" alt=""></div>
              
              <div v-if="item.answerType == '0'" class="answer">
                <div v-for="(child, idx) in JSON.parse(item.answer)" :key="idx" class="item">
                  <div v-if="child.cont">{{ alphabet[idx] }}、{{ child.cont }}</div>
                  <div v-if="child.pic"><img :src="child.pic" alt=""></div>
                </div>
                <div class="item">答案：{{ item.correctAnswer }}</div>
              </div>
              <div v-if="item.answerType == '1'" class="answer">
                <div v-for="(child, idx) in JSON.parse(item.answer)" :key="idx" class="item">
                  <div v-if="child.cont">{{ alphabet[idx] }}、{{ child.cont }}</div>
                  <div v-if="child.pic"><img :src="child.pic" alt=""></div>
                </div>
                <div class="item">答案：{{ JSON.parse(item.correctAnswer).join(', ') }}</div>
              </div>
              <div v-if="item.answerType == '3'" class="answer">
                <div class="item">答案：{{ item.correctAnswer == '1' ? '正确' : '错误' }}</div>
              </div>
              <div v-if="item.answerType == '4'" class="answer">
                <div class="item">答案：{{ JSON.parse(item.correctAnswer).join(',') }}</div>
              </div>
              <div v-if="item.answerType == '2'" class="answer">
                <div class="item">答案：{{ item.correctAnswer }}</div>
              </div>
            </div>
          </div>
          <div v-else class="question-content empty flex jc-center ai-center">
            暂无数据
          </div>
        </div>
      </div>
      <div v-else class="container-right flex-1">
        <div class="container-content">
          <div class="question-data">
            <div class="chart" id="bar-chart"></div>
            <div class="flex jc-between ai-center">
              <div class="flex-1">
                <div class="chart" id="pie-chart1"></div>
              </div>
              <div class="flex-1">
                <div class="chart" id="pie-chart2"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as echarts from "echarts";
import { topicModuleList, topicList } from '../../../api/ApiConfig'
export default {
  components: {},
  props: ['pager', 'params'],
  data() {
    return {
      alphabet: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'],
      laboratory_id: localStorage.getItem('laboratory_id') || null,
      actived: 0,
      menu: [
        {
          id: 1,
          name: '实验操作'
        },
        {
          id: 2,
          name: '实验理论'
        },
        {
          id: 3,
          name: '答题统计'
        }
      ],
      moduleId: null,
      modules: [],
      type: 1,
      date: null,
      list: [],
      myChart1: null,
      myChart2: null,
      myChart3: null,
    };
  },
  computed: {
    chartOpt1() {
      return {
        grid: {
          top: 60,
        },
        color: [
          {
            type: 'bar',
            x: 0, y: 0, x2: 0, y2: 1,
            colorStops: [{ offset: 0, color: 'rgba(50, 124, 237, 1)' }, { offset: 1, color: 'rgba(50, 124, 237, 0.2)' }],
            global: false
          },
          {
            type: 'bar',
            x: 0, y: 0, x2: 0, y2: 1,
            colorStops: [{ offset: 0, color: 'rgba(255, 117, 70, 1)' }, { offset: 1, color: 'rgba(255, 117, 70, 0.2)' }],
            global: false
          }
        ],
        legend: {
          data: ['理论题', '操作题'],
          top: 10
        },
        xAxis: {
          type: 'category',
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月']
        },
        yAxis: {
          name: '参与实验次数',
          nameTextStyle: {
            padding: [0, 0, 30, 0]
          },
          type: 'value',
          minInterval: 1,
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          splitLine: {
            show: false
          }
        },
        series: [
          {
            type: 'bar',
            name: '理论题',
            barWidth: 24,
            itemStyle: {
              barBorderRadius: 12
            },
            data: [50, 60, 70, 55, 64, 39, 76]
          },
          {
            type: 'bar',
            name: '操作题',
            barWidth: 24,
            itemStyle: {
              barBorderRadius: 12
            },
            data: [40, 45, 60, 35, 54, 49, 46]
          }
        ]
      }
    },
    chartOpt2() {
      return {
        title: {
          show: true,
          text: "模块操作出错率统计",
          left: 'center',
          textStyle: {
            color: "#234984"
          }
        },
        color: ["#327CED", "#62C558", "#FFC714", "#FF3F5C", "#7059DA"],
        tooltip: {
          trigger: "item",
          formatter: '{b0}: {d0}%'
        },
        legend: {
          orient: "vertical",
          right: "20%",
          top: "middle",
          icon: "circle",
          data: ["模块1", "模块2", "模块3", "模块4", "模块5"]
        },
        series: [
          {
            name: "模块统计",
            type: "pie",
            center: [ '30%', '50%' ],
            radius: ["35%", "50%"],
            avoidLabelOverlap: false,
            label: {
              show: true,
              position: "center",
              formatter: '模块统计',
              color: '#333',
              fontSize: 20
            },
            emphasis: {
              label: {
                show: false,
                fontSize: 40,
                fontWeight: "bold"
              }
            },
            labelLine: {
              show: false
            },
            data: [
              { value: 1048, name: "模块1" },
              { value: 735, name: "模块2" },
              { value: 580, name: "模块3" },
              { value: 484, name: "模块4" },
              { value: 300, name: "模块5" }
            ]
          }
        ]
      }
    },
    chartOpt3() {
      return {
        title: {
          show: true,
          text: "模块答题出错率统计",
          left: 'center',
          textStyle: {
            color: "#234984"
          }
        },
        color: ["#327CED", "#62C558", "#FFC714", "#FF3F5C", "#7059DA"],
        tooltip: {
          trigger: "item",
          formatter: '{b0}: {d0}%'
        },
        legend: {
          orient: "vertical",
          right: "20%",
          top: "middle",
          icon: "circle",
          data: ["模块1", "模块2", "模块3", "模块4", "模块5"]
        },
        series: [
          {
            name: "模块统计",
            type: "pie",
            center: [ '30%', '50%' ],
            radius: ["35%", "50%"],
            avoidLabelOverlap: false,
            label: {
              show: true,
              position: "center",
              formatter: '模块统计',
              color: '#333',
              fontSize: 20
            },
            emphasis: {
              label: {
                show: false,
                fontSize: 40,
                fontWeight: "bold"
              }
            },
            labelLine: {
              show: false
            },
            data: [
              { value: 1048, name: "模块1" },
              { value: 735, name: "模块2" },
              { value: 580, name: "模块3" },
              { value: 484, name: "模块4" },
              { value: 300, name: "模块5" }
            ]
          }
        ]
      }
    }
  },
  watch: {
    pager() {
    },
    params() {
    }
  },
  async mounted() {
    await this.topicModuleList()
    this.getData()
  },
  methods: {
    async topicModuleList() {
      const res = await topicModuleList()
      if (res.code === 200) {
        this.modules = [
          {
            id: '',
            name: '全部'
          },
          ...res.data.map(item => {
            item.id = String(item.id)
            return item
          })
        ]
      }
    },
    getData() {
      topicList({
        studyId: this.laboratory_id,
        moduleId: this.moduleId,
        topicType: this.actived,
        answerType: '',
        pageIndex: 1,
        pageSize: 999999
      }).then(res => {
        if (res.code === 200) {
          this.list = res.data.records || [];
        }
      });
    },
    handleChangeModule() {
      this.getData()
    },
    handleTab(index) {
      this.actived = index
      if(index === 2) {
        this.initChart()
      } else {
        this.getData()
      }
    },
    handleTypeClick(type) {
      this.type = type
      this.getData()
    },
    initChart() {
      this.$nextTick(() => {
        this.myChart1 = echarts.init(document.getElementById("bar-chart"));
        this.myChart2 = echarts.init(document.getElementById("pie-chart1"));
        this.myChart3 = echarts.init(document.getElementById("pie-chart2"));
        this.myChart1.setOption(this.chartOpt1);
        this.myChart2.setOption(this.chartOpt2);
        this.myChart3.setOption(this.chartOpt3);
        window.addEventListener('resize', () => {
          this.myChart1.resize();
          this.myChart2.resize();
          this.myChart3.resize();
        });
      })
    },
  }
};
</script>
<style lang="scss" scoped>
.questions-pager {
  background-color: #F4F5F8;
  overflow: hidden;
  .container {
    width: 1470px;
    margin: 50px auto;
    .container-left {
      width: 200px;
      margin-right: 50px;
      background: #FFFFFF;
      box-shadow: 0px 2px 14px 0px rgba(45,77,130,0.11);
      border-radius: 12px;
      .menu {
        padding: 15px;
      }
      .menu-item {
        height: 58px;
        font-weight: bold;
        font-size: 20px;
        color: #666666;
        line-height: 58px;
        text-align: center;
        &::before {
          content: ' ';
          position: relative;
          left: -10px;
          top: 3px;
          display: inline-block;
          width: 4px;
          height: 20px;
          background: transparent;
          border-radius: 1px;
        }
        &.actived {
          color: #327CED;
          &::before {
            background: #327CED;
          }
        }
      }
    }
    .container-right {
      .container-top {
        height: 82px;
        background: #FFFFFF;
        box-shadow: 0px 2px 14px 0px rgba(45,77,130,0.11);
        border-radius: 12px;
        padding: 0 20px;
        .type-btn {
          margin-left: 0;
          margin-right: 20px;
        }
      }
      .container-content {

        .question-content {
          background: #FFFFFF;
          box-shadow: 0px 2px 14px 0px rgba(45,77,130,0.11);
          border-radius: 12px;
          padding: 30px;
          margin-top: 20px;
          .wrap {
            margin: 20px 0;
            .title {
              font-weight: bold;
              font-size: 22px;
              color: #6B7D97;
              line-height: 37px;
            }
            .answer {
              padding-left: 15px;
            }
            .item {
              font-size: 18px;
              color: #6B7D97;
              line-height: 37px;
            }
          }
        }
        .question-data {
          background: #FFFFFF;
          box-shadow: 0px 2px 14px 0px rgba(45,77,130,0.11);
          border-radius: 12px;
          padding: 50px;
          .chart {
            height: 420px;
          }
        }
      }
    }
  }
}
.empty {
  padding: 20px;
  text-align: center;
  color: #666;
  font-size: 14px;
}
</style>

<style lang="scss">
.el-select-dropdown__item.custom-option {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 500px;
}
</style>
