<template>
  <div class="resource-pager">
    <div class="classify flex jc-between ai-center">
      <div v-for="(item, index) in types" :key="index"
        :class="['item cursor-pointer', { actived: actived === item.id }]" @click="handleTypeClick(item)">{{ item.name
        }}</div>
    </div>
    <div class="table flex flex-wrap">
      <div v-for="(item, index) in list" :key="index" class="item flex flex-column relative cursor-pointer">
        <img :src="item.fileUrl" :alt="item.fileName" class="img absolute">
        <div class="txt absolute">{{ item.fileName }}</div>
      </div>
    </div>
    <div class="pagination">
      <el-pagination align="center" @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="page" :page-sizes="[10, 20, 40, 50, 100]" :page-size="size"
        layout="total, sizes, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </div>
    <!-- <img src="../../assets/pcResource.png" alt=""> -->
  </div>
</template>
<script>
import { getResource } from '@/api/ApiConfig'
export default {
  components: {
  },
  data() {
    return {
      actived: '',
      types: [
        {
          id: '',
          name: '全部资料'
        },
        {
          id: '0',
          name: '文本文档'
        },
        {
          id: '1',
          name: '视频资料'
        },
        {
          id: '2',
          name: '全景图资料'
        },
        {
          id: '3',
          name: '模型资料'
        }
      ],
      page: 1,
      size: 10,
      total: 10,
      list: []
    };
  },
  created() {
    this.getData()
  },
  activated() {
  },
  methods: {
    handleTypeClick(item) {
      this.actived = item.id
      this.page = 1
      this.getData()
    },
    getData() {
      getResource({
        pageIndex: this.page,
        pageSize: this.size,
        courseSeries: this.types[2].actived,
        courseType: this.types[1].actived,
        fileType: this.types[3].actived,
        speciality: this.types[0].actived
      }).then(res => {
        if (res.code === 200) {
          console.log(res.data)
          this.list = res.data.records || [];
          this.total = res.data.total
        }
      });
    },
    handleSizeChange(val) {
      this.page = 1;
      this.size = val;
      this.getData();
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getData();
    },
  }
};
</script>
<style lang="scss">
.resource-pager {
  padding-top: 39px;

  .classify {
    width: 1400px;
    margin: 0 auto;

    .item {
      box-shadow: 0px 2px 14px 0px rgba(45, 77, 130, 0.11);
      border-radius: 10px;
      font-weight: bold;
      font-size: 20px;
      line-height: 76px;
      margin: 0 50px;
      padding: 0 38px;
      background: #FFFFFF;
      color: #666666;

      &.actived {
        background: #327CED;
        color: #FFFFFF;
      }
    }
  }

  .table {
    width: 1400px;
    margin: 20px auto;

    .item {
      width: 280px;
      height: 264px;

      .img {
        left: 0;
        top: 0;
        width: 280px;
        height: 264px;
      }

      .txt {
        left: 0;
        bottom: 20px;
        z-index: 2;
        width: 280px;
        font-weight: bold;
        font-size: 18px;
        color: #234984;
        line-height: 25px;
        text-align: center;
      }
    }
  }

  .pagination {
    margin: 30px 0;
  }
}
</style>
